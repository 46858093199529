@import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';
/* You can add global styles to this file, and also import other style files */
.pre{
    white-space: pre-line;
}

html, body{
    height: 100%;
}

.height{
    height: 100%;
}

.ng-invalid.p-element:not(p-panel):not(:read-only) {
    outline: 1px solid #ff7070;
    display: inherit;
}

p-dropdown.ng-invalid .p-dropdown, p-calendar.ng-invalid input {
    outline: 1px solid #ff7070;
}

.gap- {
    &1 {
        gap: 0.25rem;  
    }
    &2 {
        gap: 0.5rem;
    }
    &3 {
        gap: 0.75rem;
    }
    &4 {
        gap: 1rem;
    }
}

.w-{
    &300 {
        width: 300px;
    }
}

